const COLORS = {
  BLACK: '#000000',
  BRAND: {
    WHITE: '#ffffff',
    BLACK: '#111111',
    CANDY: '#ff007a',
    PURPS: '#8000ff',
  },
  BACKGROUND: {
    FLOATING_PURPS: '#f8f6ff',
    GENTLE_WARNING: '#ffd8d8',
    FADED_PALE: '#fff8ef',
    FADED_GREEN: '#f2fdeb',
    FADED_CANDY: '#fff2f8',
    YOUNG_ORANGE: '#ffe7ce',
    SOOTHING_GREEN: '#dbfddb',
    LTT_INDIGO: '#150029',
    DARK_CYAN: '#02343f',
  },
  GRAY: {
    G1: '#222222',
    G3: '#666666',
    G4: '#888888',
    G5: '#c4c4c4',
    G6: '#e2e2e2',
    G7: '#f0f0f0',
    G8: '#f8f8f8',
    G2: '#444444',
    G4A: '#9f9f9f',
  },
  WARNING_RED: {
    LIGHT_TONE_2: '#ef9494',
    TERTIARY: '#bd0303',
    DARK_TONE: '#570101',
    LIGHT_TONE_1: '#ef4c4c',
    LIGHT_TONE_3: '#ffd8d8',
    EXTRA: '#ffe5e5',
  },
  PRIMARY: {
    DREAMY_PALE: '#ffc5bb',
    HOLA_YELLOW: '#ffe900',
    PEACHY_ORANGE: '#ff9d7c',
    SUBTLE_GREEN: '#d2f260',
    JOY_MUSTARD: '#ffbc00',
    OKAY_GREEN: '#15d676',
    WARNING_RED: '#ef0404',
    OCEAN_BLUE: '#00c4eb',
    LINK_BLUE: '#1355f0',
  },
  DREAMY_PALE: {
    SECONDARY: '#e5b1a8',
    TERTIARY: '#cc9e96',
    DARK_TONE: '#a4766e',
    LIGHT_TONE_2: '#fff4f2',
    LIGHT_TONE_1: '#ffdad4',
  },
  HOLA_YELLOW: {
    3: '#807400',
    SECONDARY: '#e5d200',
    TERTIARY: '#ccba00',
    LIGHT_TONE_2: '#fffbcc',
    LIGHT_TONE_1: '#fff266',
    LIGHT_TONE_3: '#fffde5',
  },
  TEXT: {
    HOLA_YELLOW_3: '#807400',
    PEACHY_ORANGE_3: '#a4563b',
    OKAY_GREEN_3: '#088229',
    PURPS_3: '#6600cc',
    JOY_MUSTARD_3: '#906100',
    CANDY_1: '#e5006e',
    WARNING_RED_1: '#d60404',
    BEACH: '#03829d',
    LINK_BLUE_1: '#114cd6',
  },
  PEACHY_ORANGE: {
    TERTIARY: '#cc7e63',
    SECONDARY: '#e58d70',
    LIGHT_TONE_2: '#ffece5',
    LIGHT_TONE_1: '#ffc4b0',
  },
  SUBTLE_GREEN: {
    SECONDARY: '#bcd956',
    TERTIARY: '#a6bf4c',
    LIGHT_TONE_2: '#f4ffcc',
    LIGHT_TONE_1: '#e6ff8b',
    LIGHT_TONE_3: '#f9ffe5',
  },
  PURPS: {
    SECONDARY: '#7300e5',
    MEDIUM_TONE: '#8329DE',
    DARK_TONE: '#330066',
    DARK_TONE_1: '#140029',
    LEVEL_15: '#e6d1ff',
    LEVEL_10: '#f3e9ff',
    LEVEL_3: '#6600CC',
    LEVEL_20: '#E6CCFF',
    LIGHT_TONE_2: '#b266ff',
    LIGHT_TONE_1: '#9933ff',
    LIGHT_TONE_3: '#ecd9ff',
    LIGHT_TONE_4: '#f3E9ff',
  },
  VIOLET: {
    DARK_TONE: '#2c0156',
  },
  JOY_MUSTARD: {
    SECONDARY: '#e5a900',
    TERTIARY: '#cc9600',
    LIGHT_TONE_2: '#fff2cc',
    LIGHT_TONE_1: '#ffd766',
    LIGHT_TONE_3: '#fff8e5',
  },
  BUTTON: {
    OKAY_GREEN: '#13bd68',
  },
  OKAY_GREEN: {
    3: '#088943',
    TERTIARY: '#10a35a',
    DARK_TONE: '#095730',
    LIGHT_TONE_2: '#aaffd4',
    LIGHT_TONE_1: '#49ee9c',
    LIGHT_TONE_3: '#deffee',
  },
  CANDY: {
    PRIMARY: '#ff007c',
    TERTIARY: '#cc0062',
    DARK_TONE: '#660031',
    LIGHT_TONE_2: '#ff66af',
    LIGHT_TONE_1: '#fe3394',
    LIGHT_TONE_3: '#ffe5f2',
    FADED: '#FFF2F8',
  },
  OCEAN_BLUE: {
    SECONDARY: '#00afd1',
    TERTIARY: '#009ab8',
    DARK_TONE: '#004452',
    LIGHT_TONE_2: '#a4f0ff',
    LIGHT_TONE_1: '#2fcceb',
    LIGHT_TONE_3: '#d6f8ff',
  },
  LINK_BLUE: {
    TERTIARY: '#0f43bd',
    DARK_TONE: '#071f57',
    LIGHT_TONE_2: '#7ba3ff',
    LIGHT_TONE_1: '#4377f0',
    LIGHT_TONE_3: '#adc6ff',
  },
  LIGHT_GRAY: '#d9d9d9',
  LTT_BANNER_BACKGROUND_COLOR: '#150029',
  EERIE_BLACK: '#180030',
  CHINESE_BLACK: '#150029',
  MISC: {
    FADED_WHITE: '#FFFFFF4D',
    GREEN: '#078842',
    LAVENDER: '#f7f7ff',
  },
  metadata: {
    generated: 'Tue Jun 15 2021 13:18:23 GMT+0530 (India Standard Time)',
    figma: {
      name: "Color System June '20",
      url: 'https://api.figma.com/v1/files/rS3BGKSGiLr0cCdGplNl2q',
      lastModified: '2021-06-15T06:03:19Z',
    },
  },
};

export default COLORS;
